import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'admin', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  {
    path: 'admin/shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopPageModule)
  },
  {
    path: 'admin/kyc',
    loadChildren: () => import('./kyc/kyc.module').then(m => m.KycPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./index/index.module').then(m => m.IndexPageModule)
  },
  {
    path: 'admin/user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
